import { useEffect } from "react";
import { loadClarity } from "./clarity";

const ClarityProvider = ({ projectId, children }) => {
	useEffect(() => {
		loadClarity(projectId);
	}, [projectId]);

	return <>{children}</>;
};

export default ClarityProvider;
