export default class ScraperInfos {
	constructor(api) {
		this.api = api;
	}

	getScrapingTypes = () =>
		this.api
			.processRequest("GET", "/scrapers/types")
			.then((data) => data.data);

	getScrapingStatus = (scrapingName) =>
		this.api
			.processRequest("GET", `/scrapers/history/${scrapingName}/status`)
			.then((data) => data.data);

	getScrapingLogging = (id, offset = 0, limit = 500) => {
		const params = { offset, limit };
		return this.api
			.processRequest("GET", `/scrapers/logging/${id}`, { params })
			.then((data) => data.data);
	};

	getAllScrapingHistoriy = (scrapingName) => {
		const params = { scraping_name: scrapingName };
		return this.api
			.processRequest("GET", "/scrapers/history", {
				params,
			})
			.then((data) => data.data);
	};

	getScrapingHistory = (id) =>
		this.api
			.processRequest("GET", `/scrapers/history/${id}`)
			.then((data) => data.data);

	updateScrapingHistoryDismiss = (id) =>
		this.api.processRequest("PUT", `/scrapers/history/${id}`);

	getScrapingUndismissed = () =>
		this.api
			.processRequest("GET", "/scrapers/history/undismissed")
			.then((data) => data.data);

	runScrapers = (mode, year = null, select = null) => {
		const data = {
			mode,
			year,
			select,
		};
		return this.api.processRequest("POST", "/scrapers/run", { data });
	};
}
