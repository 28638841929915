let clarityLoaded = false;

export const isProd = import.meta.env.MODE === "production";

export const loadClarity = (projectId) => {
	if (!isProd) {
		console.info("[Clarity] Ignored (not in production)");
		return;
	} else if (!projectId) {
		console.info("[Clarity] Ignored (no project id configured)");
		return;
	}

	if (clarityLoaded || typeof window === "undefined" || window.clarity)
		return;

	(function (c, l, a, r, i, t, y) {
		c[a] =
			c[a] ||
			function () {
				(c[a].q = c[a].q || []).push(arguments);
			};
		t = l.createElement(r);
		t.async = true;
		t.src = "https://www.clarity.ms/tag/" + projectId;
		y = l.getElementsByTagName(r)[0];
		y.parentNode && y.parentNode.insertBefore(t, y);
	})(window, document, "clarity", "script");

	clarityLoaded = true;
};
