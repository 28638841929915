import "bootstrap";
import "core-js/stable";
import "regenerator-runtime/runtime";

import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/dist/js/adminlte";

import "@mantine/code-highlight/styles.css";

import { MsalProvider } from "@azure/msal-react";
import "@mantine/code-highlight/styles.css";
import "@mantine/core/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Chart, Filler, registerables } from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import * as ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import "mantine-react-table/styles.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import ScraperAPIHelper from "./API/ScraperAPIHelper";
import FakeOauthHandler from "./Components/Oauth2/FakeOauth2";
import OauthHandler from "./Components/Oauth2/Oauth2";
import msalInstance from "./Components/Oauth2/msalInstance";
import { Config } from "./Config/Config";
import { GlobalTranslation } from "./Config/i18n";
import ClarityProvider from "./analytics/ClarityProvider";
import { ScraperApiHelperContext } from "./contexts";

Chart.register(
	...registerables,
	ChartjsPluginStacked100.default,
	Filler,
	annotationPlugin
);
const client = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

import("../styles/override.scss");
import("../styles/default.scss");

function getOauthHandler() {
	if (Config.dev_token) return <FakeOauthHandler />;
	return (
		<MsalProvider instance={msalInstance}>
			<OauthHandler />
		</MsalProvider>
	);
}

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
	<StrictMode>
		<ClarityProvider projectId={Config.clarity_id}>
			<QueryClientProvider client={client}>
				<ToastContainer />
				<GlobalTranslation />
				<ScraperApiHelperContext.Provider
					value={new ScraperAPIHelper()}
				>
					{getOauthHandler()}
				</ScraperApiHelperContext.Provider>
			</QueryClientProvider>
		</ClarityProvider>
	</StrictMode>
);
