import { Config } from "../../Config/Config";

// Config object to be passed to Msal on creation
export const msalConfig = {
	auth: {
		clientId: Config.client_id,
		authority: `https://login.microsoftonline.com/${Config.epitech_active_directory_id}`,
		redirectUri: Config.redirect_uri,
		postLogoutRedirectUri: Config.redirect_uri,
	},
	cache: {
		cacheLocation: "localStorage",
	},
};

export const loginRequest = {
	scopes: ["User.Read"],
};
