import { colorsTuple, createTheme, virtualColor } from "@mantine/core";

export const theme = createTheme({
	colors: {
		"epitech-aqua-blue-white": colorsTuple("#1F485E"),
		"epitech-aqua-blue-dark": colorsTuple("#0b1d27"),
		"epitech-aqua-blue": virtualColor({
			name: "epitech-aqua-blue",
			light: "epitech-aqua-blue-white",
			dark: "epitech-aqua-blue-dark",
		}),
		"main-content-display-white": colorsTuple("#fafafa"),
		"main-content-display-dark": colorsTuple("#2c2e33"),
		"main-content-display": virtualColor({
			name: "main-content-display",
			light: "main-content-display-white",
			dark: "main-content-display-dark",
		}),
		"blue": [
			"#e1f8ff",
			"#cbedff",
			"#9ad7ff",
			"#64c1ff",
			"#3aaefe",
			"#20a2fe",
			"#099cff",
			"#0088e4",
			"#0079cd",
			"#0068b6",
		],
	},
	defaultRadius: "md",
});
