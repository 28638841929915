import { AppShell, Box, MantineProvider, ScrollArea } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import "dayjs/locale/fr";
import QS from "qs";
import { Suspense, useContext, useEffect, useState } from "react";
import {
	Route,
	BrowserRouter as Router,
	Routes,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { SauronRoutes } from "../../Config/Routes";
import { ScraperApiHelperContext } from "../../contexts";
import { theme } from "../../theme";
import NotFound from "../404/404";
import { usePreferedColorScheme } from "../AdminLTE3/DarkMode";
import SauronHeader from "../Sauron/SauronHeader.jsx";
import SauronNavbar from "../Sauron/SauronNavbar.jsx";
import classes from "../Sauron/SauronNavbar.module.css";
import { hasPermissions } from "../Utils/Utils";

const REG_TO_CALLBACK = [
	...[false, true, null, undefined].map((k) => ({
		test: new RegExp(`^${k}$`),
		value: k,
	})),
	{
		test: /^[+-]?([0-9]*[.])?[0-9]+$/,
		value: parseFloat,
	},
	{
		test: /^[-+]?\d+$/,
		value: parseInt,
	},
];

function applyTypes(object) {
	Object.entries(object).forEach(([key, content]) => {
		if (typeof content === "object") {
			applyTypes(object[key]);
		} else if (Array.isArray(content)) {
			for (let i = 0; i < content.length; ++i) {
				applyTypes(content[i]);
			}
		} else {
			REG_TO_CALLBACK.forEach(({ test, value }) => {
				if (test.test(content)) {
					if (typeof value === "function") {
						object[key] = value(content);
					} else {
						object[key] = value;
					}
				}
			});
		}
	});
}

function Wrapper({ Component, ...props }) {
	if (!Component) return null;
	props.params = useParams();
	props.location = useLocation();
	props.navigate = useNavigate();
	props.query = QS.parse(props.location.search, { ignoreQueryPrefix: true });
	applyTypes(props.query);
	return <Component {...props} />;
}

function routeMappingFromConfig(routes, account) {
	return Object.entries(routes)
		.filter(([, page]) => hasPermissions(page, account))
		.map(([key, route]) => (
			<Route
				key={key}
				path={route.path}
				element={
					<Box>
						<Wrapper Component={route.component} />
					</Box>
				}
			/>
		));
}

function notFound() {
	return <Route element={<Wrapper Component={NotFound} />} />;
}

export default function SauronRouter() {
	const preferedColorScheme = usePreferedColorScheme();
	const [colorScheme] = useState(preferedColorScheme);
	const scraper = useContext(ScraperApiHelperContext);
	const userInfos = scraper.api.getSauronUserInfo();
	const routeComponents = routeMappingFromConfig(SauronRoutes, userInfos);

	useEffect(() => {
		if (colorScheme === "dark") {
			document.body.classList.add("dark-mode");
		}
	}, [colorScheme]);
	const [opened, { toggle }] = useDisclosure();

	return (
		<Suspense fallback={null}>
			<DatesProvider settings={{ locale: "fr" }}>
				<MantineProvider
					theme={theme}
					withGlobalStyles
					withNormalizeCSS
				>
					<Router>
						<AppShell
							header={{ height: 70 }}
							navbar={{
								width: { sm: 200, lg: 250 },
								breakpoint: "sm",
								collapsed: { mobile: !opened },
							}}
							padding="xs"
						>
							<AppShell.Header
								bg="epitech-aqua-blue"
								className={classes.header}
							>
								<SauronHeader
									burgerOpened={opened}
									burgerToggle={toggle}
								/>
							</AppShell.Header>

							<AppShell.Navbar
								p="xs"
								component={ScrollArea}
								bg="epitech-aqua-blue"
							>
								<SauronNavbar
									burgerOpened={opened}
									burgerToggle={toggle}
								/>
							</AppShell.Navbar>

							<AppShell.Main bg="main-content-display">
								<Routes>
									{routeComponents}
									{notFound()}
								</Routes>
							</AppShell.Main>
						</AppShell>
					</Router>
				</MantineProvider>
			</DatesProvider>
		</Suspense>
	);
}
