export const Config = {
	client_id: import.meta.env.VITE_EPITECH_APP_CLIENT_ID,
	redirect_uri: import.meta.env.VITE_REDIRECT_URI,
	epitech_active_directory_id: import.meta.env
		.VITE_EPITECH_AZURE_AD_TENANT_ID,
	scraper_uri: import.meta.env.VITE_SCRAPER_URI,
	debug: import.meta.env.DEV,
	dev_token: import.meta.env.VITE_DEV_TOKEN,
	clarity_id: import.meta.env.VITE_CLARITY_ID,
};

Object.entries(Config).forEach(([key, value]) => {
	if (value === undefined) {
		console.error(`"${key}" config key didn't load properly`);
	}
});
